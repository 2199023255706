import React from 'react';
import { graphql } from 'gatsby';
import CompatibilidadeAmorosaBlock from '../components/compatibilidade-amorosa-block'
import Layout from "../components/layout"
import SEO from '../components/seo';
import {
  Section,
  Content,
} from "bloomer"  

export default ({ data }) => {
  //const post = data.markdownRemark;
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO title={`Compatibilidade Amorosa ${frontmatter.title}- Estrelaguia Guia`} />
      <Content>
        <article>
          <header className="content">    
            <h1>{frontmatter.title}</h1>
          </header>
          <Content dangerouslySetInnerHTML={{ __html: html }}/>
          </article>
          {/* <CompatibilidadeAmorosaBlock /> */}

      </Content>
      <Content>
        <h2>Compatibilidade Amorosa</h2>
        <p>Seu amor estava escrito nas estrelas ou é um verdadeiro desastre astral? Descubra o que os astros tem a dizer sobre seu relacionamento amoroso. Selecione o seu signo e depois o de seu parceiro/a e clique em Combinar. Saiba quais são os pontos fortes e os fracos dessa relação e faça com que dê certo.</p>
        <p>Descubra o que os astros têm a dizer sobre seu relacionamento!</p>
      </Content>
      <CompatibilidadeAmorosaBlock />
    </Layout> 
  );

};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        mname
      }
      html
    }
  }
`;